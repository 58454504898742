import store from "@/store";
import { Module, VuexModule, MutationAction, getModule, Action, Mutation } from "vuex-module-decorators";
import API from "@/store/API/meta";
import { Province, District, Palika, FiscalYear, BankCategory } from "@/store/models/meta";

@Module({ name: "metaStore", dynamic: true, store })
class MetaModule extends VuexModule {
  public Province: Province[] = [];
  public District: District[] = [];
  public Palika: Palika[] = [];
  public BankList: BankCategory[] = [];
  public FiscalYear: FiscalYear[] = [];

  public mobileScreen: boolean = false;
  public reload: boolean = false;


  @Mutation
  setMobileScreen(mobileScreen: boolean) {
    this.mobileScreen = mobileScreen;
  }


  @Mutation
  setReload(reload: boolean) {
    this.reload = reload;
  }

  @MutationAction
  async getAllProvince() {
    var province = (this.state as MetaModule).Province;
    if (province.length === 0) {
      province = await API.getAllProvince();
    }
    return { Province: province as Province[] };
  }

  @MutationAction
  async getAllDistrict() {
    var district = (this.state as MetaModule).District;
    if (district.length === 0) {
      district = await API.getAllDistrict();
    }
    return { District: district as District[] };
  }

  @MutationAction
  async getAllPalika() {
    var palika = (this.state as MetaModule).Palika;
    if (palika.length === 0) {
      palika = await API.getAllPalika();
    }
    return { Palika: palika as Palika[] };
  }

  @MutationAction
  async getAllBankList() {
    var banklist = (this.state as MetaModule).BankList;
    if (banklist.length === 0) {
      banklist = await API.getAllBankList();
    }
    return { BankList: banklist as BankCategory[] };
  }

  @MutationAction
  async getAllFiscalYear() {
    var fiscalYear = (this.state as MetaModule).FiscalYear;
    if (fiscalYear.length === 0) {
      fiscalYear = await API.getAllFiscalYear();
    }
    return { FiscalYear: fiscalYear as FiscalYear[] };
  }

  @Action
  async getCropIdBySlug(slug: string) {
    return await API.getCropIdBySlug(slug);
  }

  @Action
  async getCropVarietyIdBySlug(slug: string) {
    return await API.getCropVarietyIdBySlug(slug);
  }
}

export default getModule(MetaModule);
