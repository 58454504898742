import store from "@/store";
import { Action, Module, VuexModule, MutationAction, getModule } from "vuex-module-decorators";
import API from "@/store/API/report";
import { CategoryReportPaginated } from "@/store/models/report";

@Module({ name: "reportStore", dynamic: true, store })
class ReportModule extends VuexModule {
  public CategoryReportPaginated: CategoryReportPaginated = {} as CategoryReportPaginated;
  public AdHocReportList: any[] = [];

  @MutationAction
  async getCategoryReport(params: {
    page?: number;
    fiscalYearId?: number;
    cropCategoryId?: number;
    seedTypeId?: number;
  }) {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.cropCategoryId) {
        query += "&cropcategoryid=" + String(params.cropCategoryId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
    }
    let categoryReport = await API.getCategoryReport(query);
    return { CategoryReportPaginated: categoryReport as CategoryReportPaginated };
  }

  @MutationAction
  async getAdHocReport(role: string) {
    let query = "?&role=" + String(role);
    let adHocReport = await API.getAdHocReport(query);
    return { AdHocReportList: adHocReport as any[] };
  }

  @Action
  async getTransactionReport(params: { fiscalYearId: number; provinceId?: number | null }) {
    let query = `?&fiscalyearid=${params.fiscalYearId}`;
    if (params.provinceId) {
      query += `&provinceid=${params.provinceId}`;
    }
    let report = await API.getTransactionReport(query);
    return report;
  }

  @Action
  async getSupplyVsDemandReport(params: {
    fiscalYearId: number;
    cropTypeId: number;
    seedTypeId: number;
  }) {
    let query = `?fiscal_year=${params.fiscalYearId}&crop=${params.cropTypeId}&seed_type=${params.seedTypeId}`;
    let report = await API.getSupplyVsDemandReport(query);
    return report;
  }
}

export default getModule(ReportModule);
