import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
  Action,
  getModule
} from "vuex-module-decorators";
import API from "@/store/API/user";
import {
  User,
  Role,
  PaginatedUser,
  PaginatedCompanyUser,
  PaginatedSubsidyUser,
  SourceSeedProducer,
  ImprovedSeedProducer,
  ImprovedSeedDistributer,
  ImprovedSeedUser,
  SendMail,
  DSADgroup,
  AdminCount,
  AKCUser,
  DOADUser,
  SubsidyBaseUser
} from "@/store/models/user";
import { SubsidyDocument } from "@/store/models/crop";
import { SeedType } from "../models/DemandAndSupply";

@Module({ name: "userStore", dynamic: true, store })
class UserModule extends VuexModule {
  public Roles: Role[] = [];
  public Users: User[] = [];
  public AdminUsers: PaginatedUser = {} as PaginatedUser;
  public GeneralUsers: PaginatedUser = {} as PaginatedUser;
  public SeedCompany: PaginatedCompanyUser = {} as PaginatedCompanyUser;
  public ConsumerCompany: PaginatedCompanyUser = {} as PaginatedCompanyUser;
  public ImprovedSeedConsumer: PaginatedCompanyUser = {} as PaginatedCompanyUser;
  public ImprovedSeedSupplier: PaginatedCompanyUser = {} as PaginatedCompanyUser;
  public User: User = {} as User;
  public UserDetail:
    | SourceSeedProducer
    | ImprovedSeedProducer
    | ImprovedSeedDistributer
    | ImprovedSeedUser = {} as SourceSeedProducer;
  public SendMail: SendMail[] = [] as SendMail[];
  public DSADgroup: DSADgroup[] = [] as DSADgroup[];

  public PendingSSPUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public PendingISPUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public PendingISDUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public PendingISCUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public DLAO: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public DOADViewer: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public AKCUser: AKCUser = {} as AKCUser;
  public DOADUser: DOADUser = {} as DOADUser;

  public UserDocuments: PaginatedCompanyUser = {} as PaginatedCompanyUser;

  public RecentApprovedUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public AllApprovedUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public ApprovedSSPUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public ApprovedISPUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public ApprovedISDUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public ApprovedISCUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public AllApprovedISCUsers: SubsidyBaseUser[] = [] as SubsidyBaseUser[];

  public RejectedSSPUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public RejectedISPUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public RejectedISDUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public RejectedISCUsers: PaginatedSubsidyUser = {} as PaginatedSubsidyUser;
  public AdminCount: AdminCount = {} as AdminCount;

  UploadedDocuments: SubsidyDocument[] = [] as SubsidyDocument[];

  @MutationAction
  async getUserList() {
    const users = await API.getAllUserList();
    return { Users: users as User[] };
  }

  @MutationAction
  async getAdminUserList(
    params: { searchString?: any; page?: any } = { searchString: undefined, page: 1 }
  ) {
    let query = "/";
    if (params.searchString || params.page) {
      query += "?";
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getAdminUserList(query);
    return { AdminUsers: paginatedUsers as PaginatedUser };
  }

  @MutationAction
  async getGeneralUserList(
    params: { searchString?: any; page?: any } = { searchString: undefined, page: 1 }
  ) {
    let query = "/";
    if (params.searchString || params.page) {
      query += "?";
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getGeneralUserList(query);
    return { GeneralUsers: paginatedUsers as PaginatedUser };
  }

  @MutationAction
  async getSeedCompanyUserList(
    params: { searchString?: any; page?: any; approved?: any } = {
      searchString: undefined,
      page: 1
    }
  ) {
    let query = "/";
    if (params.searchString || params.page || params.approved) {
      query += "?";
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    if (params.approved) {
      query += "&approved=" + params.approved;
    }
    const paginatedUsers = await API.getSeedCompanyList(query);
    return { SeedCompany: paginatedUsers as PaginatedCompanyUser };
  }

  @MutationAction
  async getImprovedSeedConsumerList(
    params: { searchString?: any; page?: any; approved?: any } = {
      searchString: undefined,
      page: 1
    }
  ) {
    console.log(params);
    let query = "/";
    if (params.searchString || params.page || params.approved) {
      query += "?";
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    if (params.approved) {
      query += "&approved=" + params.approved;
    }
    const paginatedUsers = await API.getImprovedSeedConsumerList(query);
    return { ImprovedSeedConsumer: paginatedUsers as PaginatedCompanyUser };
  }

  @MutationAction
  async getConsumerCompanyUserList(
    params: { searchString?: any; page?: any; approved?: any } = {
      searchString: undefined,
      page: 1
    }
  ) {
    let query = "/";
    if (params.searchString || params.page || params.approved) {
      query += "?";
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    if (params.approved) {
      query += "&approved=" + params.approved;
    }
    const paginatedUsers = await API.getConsumerCompanyList(query);
    return { ConsumerCompany: paginatedUsers as PaginatedCompanyUser };
  }

  @Action
  async registerCompany(params: any) {
    return await API.registerCompany(params);
  }

  @Action
  async registerSourceSeedProducer(params: any) {
    return await API.registerSourceSeedProducer(params);
  }

  @Action
  async updateUserDocuments(params: any) {
    return await API.updateUserDocuments(params);
  }

  @MutationAction
  async getUploadedUserDocuments(params?: any) {
    let query = "";
    if (params) {
      query += "?";
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    if (params.selectedProvince) {
      query += "&companyuser__provinceid=" + params.selectedProvince;
    }
    if (params.selectedDistrict) {
      query += "&companyuser__districtid=" + params.selectedDistrict;
    }
    if (params.selectedPalika) {
      query += "&companyuser__palikaid=" + params.selectedPalika;
    }
    if (params.reapproveStatus) {
      query += "&companyuser__reapprove_status=" + params.reapproveStatus;
    }
    if (params.selectedRole) {
      query += "&companyuser__role=" + params.selectedRole;
    }
    const documents = await API.getUploadedUserDocuments(query);
    return { UserDocuments: documents };
  }
  @Action
  async registerImprovedSeedProducer(params: any) {
    return await API.registerImprovedSeedProducer(params);
  }

  @Action
  async patchImprovedSeedProducer(params: { id: any; company: FormData }) {
    return await API.patchImprovedSeedProducer(params.id, params.company);
  }

  @Action
  async registerImprovedSeedDistributor(params: any) {
    return await API.registerImprovedSeedDistributor(params);
  }

  @Action
  async registerImprovedSeedUser(params: any) {
    return await API.registerImprovedSeedUser(params);
  }

  @Action
  async registerAKCUser(params: any) {
    return await API.registerAKCUser(params);
  }

  @Action
  async updateAKCUser(params: { id: any; user: any }): Promise<any> {
    const response = await API.updateAKCUser(params.id, params.user);
    return response;
  }

  @MutationAction
  async getAKCUserById(params: { id: any }): Promise<any> {
    const response = await API.getAKCUserById(params.id);
    return { AKCUser: response as any };
  }

  @Action
  async registerDOADUser(params: any) {
    return await API.registerDOADUser(params);
  }

  @MutationAction
  async getDOADUserById(params: { id: any }): Promise<any> {
    const response = await API.getDOADUserById(params.id);
    return { DOADUser: response as any };
  }

  @Action
  async updateDOADUser(params: { id: any; user: any }): Promise<any> {
    const response = await API.updateDOADUser(params.id, params.user);
    return response;
  }

  @Action
  async EditCompanyProfile(params: any) {
    return await API.EditCompanyProfile(params);
  }

  @Action
  async getCompanyByUserId(id: number) {
    return await API.getCompanyByUserId(id);
  }

  @MutationAction
  async getCompanyByUserSlug(slug: string) {
    let user = await API.getCompanyByUserSlug(slug);
    return { UserDetail: user };
  }

  @Action
  async getUserDetailPdf(id: number) {
    return await API.getUserDetailPdf(id);
  }

  @Action
  async approveCompanyById(params: { id: number; status?: any; seedtype?: any }) {
    let res = await API.approveCompany({
      id: params.id,
      status: params.status,
      seedtype: params.seedtype
    });
  }

  @MutationAction
  async rejectCompanyById(params: any) {
    var userListing = (this.state as UserModule).ConsumerCompany;
    let obj = await API.rejectCompany(params);
    let index = userListing.results.findIndex(x => x.id == params.id);
    userListing.results.splice(index, 1, obj);
    return { ConsumerCompany: userListing as PaginatedCompanyUser };
  }
  // @MutationAction
  // async loadUsers(forced: boolean = false) {
  // var userListing = (this.state as UserModule).Users;
  //     const user = await API.loadUsers();
  //     return { Users: user as User[]};
  // }

  // @MutationAction
  // async saveUsers(userList: User[]) {
  //   const list = await API.saveUsers(userList);
  //   return { Users: list as User[] };
  // }

  @Mutation
  updateUserList(param: { user: User; index: number }) {
    this.Users.splice(param.index, 1, param.user);
  }

  @Mutation
  pushUserList(user: User) {
    this.Users.push(user);
  }

  // @Mutation
  // async deleteCustomUser(params: { id: number; index: number }) {
  //   if (params.id !== 0) {
  //     const user = await API.deleteCustomUser(params.id);
  //   }
  //   this.Users.splice(params.index, 1);
  //   return true;
  // }

  // @MutationAction
  // async searchUsers(params: { users: User[]; searchString?: any; page?: any }) {
  //   API.saveUsers(params.users);
  //   let query = "/";
  //   if (params.searchString || params.page) {
  //     query += "?";
  //   }
  //   if (params.searchString) {
  //     query += "&search=" + params.searchString;
  //   }
  //   if (params.page) {
  //     query += "&paging=true&page=" + params.page;
  //   }
  //   const res = (await API.loadUsers(query)) as Page;
  //   const list = res.results;
  //   return { Users: list as User[], Page: res as Page };
  // }

  //End

  // @MutationAction
  // async loadRoles() {
  //   var roleListing = (this.state as UserModule).Roles;
  //     roleListing = await API.loadRoles();
  //   return { Roles: roleListing as Role[] };
  // }

  @MutationAction
  async getUser() {
    const user = await API.getUser();
    return { User: user as User, UserDetail: user as any };
  }

  // @MutationAction
  // async getRole() {
  //   const user = await API.loadRoles();
  //   return { User: user as User };
  // }

  @Mutation
  updateUser(user: any) {
    this.User = user;
  }

  @MutationAction
  public async changeProfilePicture(saveData: any): Promise<any> {
    let user = await API.changeProfilePicture(saveData);
    return { User: user as User };
  }

  @Mutation
  public async deleteCompanyUserById(id: number): Promise<any> {
    let user = await API.deleteCompanyUserById(id);
    let index = this.ConsumerCompany.results.findIndex(x => x.id == id);
    this.ConsumerCompany.results.splice(index, 1);
  }

  @Mutation
  public async deleteCustomUserById(id: number): Promise<any> {
    let user = await API.deleteCustomUserById(id);
    let index = this.GeneralUsers.results.findIndex(x => x.id == id);
    this.GeneralUsers.results.splice(index, 1);
  }

  @MutationAction
  async saveAdminUser(params: User) {
    const user = await API.saveAdminUser(params);
    return { AdminUsers: user as PaginatedUser };
  }

  // @MutationAction
  // async sendmaillogs(): Promise<any> {
  //   let sendmailList = await API.sendmaillogs();
  //   return { SendMail: sendmailList as SendMail };
  // }

  @MutationAction
  async sendmaillogs(params: {
    page?: number;
    fiscalYearId?: number;
    transactionName?: string;
    groupId?: number;
    cropVarietyId?: string;
    seedTypeId?: string;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.page) {
        query += "&page=" + String(params.page);
      }
      if (params.fiscalYearId) {
        query += "&fiscalyearid=" + String(params.fiscalYearId);
      }
      if (params.transactionName) {
        query += "&transactionname=" + String(params.transactionName);
      }
      if (params.groupId) {
        query += "&groupid=" + String(params.groupId);
      }
      if (params.cropVarietyId) {
        query += "&cropvarietyid=" + String(params.cropVarietyId);
      }
      if (params.seedTypeId) {
        query += "&seedtypeid=" + String(params.seedTypeId);
      }
    }
    let sendmailList = await API.sendmaillogs(query);
    return { SendMail: sendmailList as SendMail };
  }

  @MutationAction
  async dsadgroup(): Promise<any> {
    let dsadgroup = await API.dsadgroup();
    return { DSADgroup: dsadgroup as DSADgroup };
  }

  @MutationAction
  async getPendingSSPUserList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=source_seed_producer";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getPendingSubsidyUserList(query);
    return { PendingSSPUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getPendingISPUserList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=improved_seed_producer";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getPendingSubsidyUserList(query);
    return { PendingISPUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getDLAOList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=DLAO";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getDLAOList(query);
    return { DLAO: paginatedUsers as PaginatedSubsidyUser };
  }

  // @MutationAction
  // async getDLOAUserById(params: { id: Number }){
  //   const paginatedUsers = await API.getDLAOUserById(params.id)
  //   return { DLAO: paginatedUsers as PaginatedSubsidyUser }
  // }

  @MutationAction
  async getPaginatedDOADViewerList(
    params: {
      searchString?: any;
      provinceId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      page: 1
    }
  ) {
    let query = "?role=DOADViewer";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getDOADViewerList(query);
    return { DOADViewer: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getPendingISDUserList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=improved_seed_distributor";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getPendingSubsidyUserList(query);
    return { PendingISDUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getPendingISCUserList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=improved_seed_consumer";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getPendingSubsidyUserList(query);
    return { PendingISCUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getRecentApprovedUserList() {
    const paginatedUsers = await API.getApprovedSubsidyUserList();
    return { RecentApprovedUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getAllApprovedUserList(
    params: {
      searchString?: any;
      provinceId?: number | null;
      districtId?: number | null;
      role?: string;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.role) {
      query += `&role=${params.role}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getApprovedSubsidyUserList(query);
    return { AllApprovedUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getApprovedSSPUserList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=source_seed_producer";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getApprovedSubsidyUserList(query);
    return { ApprovedSSPUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getApprovedISPUserList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=improved_seed_producer";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getApprovedSubsidyUserList(query);
    return { ApprovedISPUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getApprovedISDUserList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=improved_seed_distributor";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getApprovedSubsidyUserList(query);
    return { ApprovedISDUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getApprovedISCUserList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=improved_seed_consumer";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getApprovedSubsidyUserList(query);
    return { ApprovedISCUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getAllApprovedISCUserList(
    params: {
      page?: any;
      pageSize?: any;
    } = {
      page: 1
    }
  ) {
    let query = "?role=improved_seed_consumer";
    if (params.page) {
      query += "&page=" + params.page;
    }
    if (params.pageSize) {
      query += "&page_size=" + params.pageSize;
    }
    query += "&ordering=companyuser__company_nepname";
    const users = await API.getApprovedSubsidyUserList(query);
    return { AllApprovedISCUsers: users as SubsidyBaseUser[] };
  }

  @MutationAction
  async getRejectedSSPUserList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=source_seed_producer";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getRejectedSubsidyUserList(query);
    return { RejectedSSPUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getRejectedISPUserList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=improved_seed_producer";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getRejectedSubsidyUserList(query);
    return { RejectedISPUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getRejectedISDUserList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=improved_seed_distributor";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getRejectedSubsidyUserList(query);
    return { RejectedISDUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getRejectedISCUserList(
    params: {
      searchString?: any;
      provinceId: number | null;
      districtId: number | null;
      page?: any;
      approved?: any;
    } = {
      searchString: undefined,
      provinceId: null,
      districtId: null,
      page: 1
    }
  ) {
    let query = "?role=improved_seed_consumer";
    if (params.provinceId) {
      query += `&province=${params.provinceId}`;
    }
    if (params.districtId) {
      query += `&district=${params.districtId}`;
    }
    if (params.searchString) {
      query += "&search=" + params.searchString;
    }
    if (params.page) {
      query += "&page=" + params.page;
    }
    const paginatedUsers = await API.getRejectedSubsidyUserList(query);
    return { RejectedISCUsers: paginatedUsers as PaginatedSubsidyUser };
  }

  @MutationAction
  async getAdminCount() {
    const value = await API.getAdminCount();
    console.log(value);
    return { AdminCount: value as AdminCount };
  }

  @Action
  async approveUserDocument(userId: number) {
    return await API.approveUserDocument(userId);
  }

  @Action
  async rejectUserDocument(userId: number) {
    return await API.rejectUserDocument(userId);
  }

  @Action
  async reverifyDocument() {
    return await API.reverifyDocument();
  }
}

export default getModule(UserModule);
